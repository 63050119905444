import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "import/export",
    title: "Import/Export",
    type: "item",
    icon: <Icon.FolderPlus size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/importuser",
    paddingTop: "15px",
  },
  {
    id: "orders",
    title: "Orders",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/orders",
  },
  {
    id: "gifts",
    title: "Gifts",
    type: "item",
    icon: <Icon.Gift size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/gifts",
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/products",
  },
  {
    id: "promo",
    title: "Promotions",
    type: "item",
    icon: <Icon.Gift size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/promotions",
  },
  {
    id: "complaint",
    title: "Queries",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/queries",
  },
  {
    id: "userManagement",
    title: "Users",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/users",
  },
];

export default navigationConfig;
