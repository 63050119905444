import { combineReducers } from "redux";
import app from "./app/reducer";
import auth from "./auth/index";
import customizer from "./customizer/index";
import navbar from "./navbar/Index";

const rootReducer = combineReducers({
  customizer: customizer,
  navbar: navbar,
  auth: auth,
  app: app,
});
export default rootReducer;
