import {
  FETCH_PRODUCTS,
  FETCH_ORDERS,
  FETCH_GIFTS,
  FETCH_ORDER_SUMMARY,
  FETCH_COMPLAINTS,
  FETCH_COMPLAINT_SUMMARY,
  LOGIN_SUCCESS,
  IMPORT_USER,
  FETCH_USERS_LIST,
  FETCH_PROMO,
  ACTIVATE_PROMO,
  DEACTIVATE_PROMO,
  CREATE_PROMO,
  CREATE_USER,
  SINGLE_PROMO,
  ACCEPT_ORDER,
  REJECT_ORDER,
  FETCH_QUERIES,
  SEND_REPLY,
  GET_IDS,
  EDIT_GIFTS,
  UPLOAD_IMAGE,
  EXPORT_USER,
} from "../../actions/action-types";

const INITIALSTATE = {
  singlePromo: {},
  user: {},
  products: [],
  orders: [],
  orderStatus: "",
  orderSummary: [],
  complaints: [],
  importUserRecord: [],
  exportUserRecord: [],
  users: [],
  promo: [],
  promoStatus: "",
  createdPromo: [],
  complaintSummary: [],
  createdUser: {},
  queryData: [],
  reply: [],
  userId: [],
  gifts: [],
  giftStatus: "",
  imageLink: "",
};

export const appReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case FETCH_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case FETCH_GIFTS: {
      return {
        ...state,
        gifts: action.payload,
      };
    }
    case EDIT_GIFTS: {
      return {
        ...state,
        giftStatus: action.payload,
      };
    }
    case FETCH_PROMO: {
      return {
        ...state,
        promo: action.payload,
      };
    }
    case SINGLE_PROMO: {
      const newPromo = action.payload;
      return {
        ...state,
        singlePromo: newPromo,
      };
    }
    case ACTIVATE_PROMO: {
      return {
        ...state,
        promoStatus: action.payload,
      };
    }
    case DEACTIVATE_PROMO: {
      return {
        ...state,
        promoStatus: action.payload,
      };
    }
    case CREATE_PROMO: {
      const newPromo = action.payload;
      return {
        ...state,
        createdPromo: newPromo,
      };
    }
    case UPLOAD_IMAGE: {
      const newPromo = action.payload;
      return {
        ...state,
        imageLink: newPromo,
      };
    }
    case FETCH_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      };
    }
    case ACCEPT_ORDER: {
      return {
        ...state,
        orderStatus: action.payload,
      };
    }
    case REJECT_ORDER: {
      return {
        ...state,
        orderStatus: action.payload,
      };
    }
    case FETCH_ORDER_SUMMARY: {
      return {
        ...state,
        orderSummary: action.payload,
      };
    }
    case FETCH_COMPLAINTS: {
      return {
        ...state,
        complaints: action.payload,
      };
    }
    case CREATE_USER: {
      return {
        ...state,
        createdUser: action.payload,
      };
    }
    case FETCH_COMPLAINT_SUMMARY: {
      return {
        ...state,
        complaintSummary: action.payload,
      };
    }
    case FETCH_USERS_LIST: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case IMPORT_USER: {
      return {
        ...state,
        importUserRecord: action.payload,
      };
    }
    case EXPORT_USER: {
      return {
        ...state,
        exportUserRecord: action.payload,
      };
    }
    case FETCH_QUERIES: {
      return {
        ...state,
        queryData: action.payload,
      };
    }
    case GET_IDS: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case SEND_REPLY: {
      const itemIndex = state.queryData.findIndex(
        (el) => el._id === action.payload._id
      );
      return {
        ...state,
        queryData: [
          ...state.queryData.slice(0, itemIndex),
          action.payload,
          ...state.queryData.slice(itemIndex + 1),
        ],
      };
    }
    default:
      return state;
  }
};
export default appReducer;
