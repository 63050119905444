export const FETCH_ORDERS = "FETCH_ORDERS";
export const ACCEPT_ORDER = "ACCEPT_ORDER";
export const REJECT_ORDER = "REJECT_ORDER";
export const FETCH_ORDER_SUMMARY = "FETCH_ORDER_SUMMARY";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PROMO = "FETCH_PROMO";
export const EDIT_PROMO = "EDIT_PROMO";
export const SINGLE_PROMO = "SINGLE_PROMO";
export const ACTIVATE_PROMO = "ACTIVATE_PROMO";
export const DEACTIVATE_PROMO = "DEACTIVATE_PROMO";
export const CREATE_PROMO = "CREATE_PROMO";
export const FETCH_COMPLAINTS = "FETCH_COMPLAINTS";
export const FETCH_COMPLAINT_SUMMARY = "FETCH_COMPLAINT_SUMMARY";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const LOGOUT = "USERS_LOGOUT";
export const IMPORT_USER = "IMPORT_USER";
export const FETCH_USERS_LIST = "FETCH_USERS_LIST";
export const CREATE_USER = "CREATE_USER";
export const FETCH_QUERIES = "FETCH_QUERIES";
export const SEND_REPLY = "SEND_REPLY";
export const GET_IDS = "GET_IDS";
export const FETCH_GIFTS = "FETCH GIFTS";
export const EDIT_GIFTS = "EDIT GIFTS";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const EXPORT_USER = "EXPORT_USER";
