import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { history } from "../../../history";
import user from "../../../assets/img/user/avatar.png";
import { connect } from "react-redux";

const UserDropdown = (props) => {
  const logoutHandler = () => {
    localStorage.clear("login");
    history.push("/login");
  };
  return (
    <DropdownMenu right>
      <DropdownItem divider />
      <DropdownItem tag="a" onClick={logoutHandler}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    suggestions: [],
  };

  render() {
    const { auth } = this.props;
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex">{auth.email}</div>
            <img
              className="round"
              src={user}
              height="40"
              width="40"
              alt="avatar"
            />
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.app.user.user,
  };
};
export default connect(mapStateToProps)(NavbarUser);
